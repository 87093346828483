<template>
  <!-- <div class="menu" style="width: 64px"> -->
  <div class="menu" :style="{ width: menuWidth }">
    <el-scrollbar style="height: 100%">
      <div class="logobox">
        <img class="logoimg" src="@/assets/imgs/logo.png" alt="" />
      </div>
      <el-menu
        :default-active="activePath"
        class="el-menu-vertical-demo my-el-menu"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isHideMenu"
        :collapse-transition="false"
        :unique-opened="true"
        text-color="rgb(191, 203, 217)"
        background-color="#263445"
        :router="true"
      >
        <!-- 菜单 -->
        <!-- 一级菜单 -->
        <template v-for="item in menus">
          <el-submenu
            v-if="item.subMenus && item.subMenus.length"
            :index="item.link"
            :key="item.link"
          >
            <template slot="title"
              ><i class="iconfont" :class="item.icon"></i
              ><span>{{ item.title }}</span></template
            >

            <!-- 二级菜单 -->
            <template v-for="itemChild in item.subMenus">
              <el-submenu
                v-if="itemChild.subMenus && itemChild.subMenus.length"
                :index="itemChild.link"
                :key="itemChild.link + 'i'"
              >
                <template slot="title"
                  ><i class="iconfont" :class="itemChild.icon"></i
                  ><span>{{ itemChild.title }}</span></template
                >

                <!-- 三级菜单 -->
                <el-menu-item
                  v-for="itemChild_Child in itemChild.subMenus"
                  :index="itemChild_Child.link"
                  :key="itemChild_Child.link"
                >
                  <i class="iconfont" :class="itemChild_Child.icon"></i
                  ><span slot="title">{{
                    itemChild_Child.title
                  }}</span></el-menu-item
                >
              </el-submenu>

              <el-menu-item v-else :index="itemChild.link" :key="itemChild.link"
                ><i class="iconfont" :class="itemChild.icon"></i
                ><span slot="title">{{ itemChild.title }}</span></el-menu-item
              >
            </template>
          </el-submenu>

          <el-menu-item v-else :index="item.link" :key="item.link + 'j'"
            ><i class="iconfont" :class="item.icon"></i
            ><span slot="title">{{ item.title }}</span></el-menu-item
          >
        </template>
        <!-- <el-submenu v-for="item in menus" :index="item.link" :key="item.link">
        <template slot="title">
          <i class="iconfont" :class="item.icon"></i>
          <span
            slot="title"
            v-show="menuWidth == '200px'"
            v-text="item.title"
          ></span>
        </template>
        <el-menu-item
          v-for="mitem in item.subMenus"
          :index="mitem.link"
          :key="mitem.link"
        >
          <i class="iconfont" :class="mitem.icon" style="margin-right: 10px"></i
          >{{ mitem.title }}
        </el-menu-item>
      </el-submenu> -->
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
// import { brequest } from '@/utils/brequest'
import { mapState } from 'vuex'

export default {
  inject: ['reload'],
  computed: {
    ...mapState({
      menus: (state) => state.auths.menus
    })
  },
  props: {
    menuWidth: {
      type: String,
      default: '200px'
    }
  },
  data() {
    return {
      // menus: [
      //   {
      //     id: 1,
      //     title: '报告管理',
      //     icon: 'icon-xiangmu1',
      //     link: '27',
      //     order: 99999,
      //     parentId: 0,
      //     subMenus: [
      //       {
      //         id: 28,
      //         icon: 'icon-dianjianguanli',
      //         link: '/inspectReport',
      //         order: 100003,
      //         parentId: 26,
      //         title: '检查报告'
      //       }
      //     ]
      //   }
      // ],
      // 是否隐藏菜单
      isHideMenu: false,
      activePath: ''
    }
  },
  methods: {
    // async queryUserMenus() {
    //   let ret = await brequest({
    //     url: '/user/queryUserMenus',
    //     method: 'post'
    //   })
    //   //权限id判断是否展示子菜单
    //   this.menus = ret
    // },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    handleSelect() {
      this.activePath = this.$route.path
    }
  },
  watch: {
    menuWidth(newData) {
      this.isHideMenu = newData == '200px' ? false : true
    },
    $route() {
      this.handleSelect()
    }
  },
  mounted() {
    // this.queryUserMenus()
    // this.activePath = this.$route.path
    this.handleSelect()
  }
}
</script>

<style lang="scss" scoped>
// menu
.menu {
  width: 200px;
  height: 100%;
  background-color: #263445;
  transition: all 0.3s;
  .logobox {
    height: 40px;
    line-height: 40px;
    color: #9d9d9d;
    font-size: 16px;
    text-align: center;
    padding: 20px 0px;
  }
  .logoimg {
    height: 40px;
  }
  .el-menu {
    border: none;

    .iconfont {
      color: #c3c8d3;
      font-size: 16px;
      font-weight: bold;
      margin-right: 5px;
    }
    .el-menu-item .iconfont {
      font-size: 16px !important;
    }
  }
  .el-submenu .el-menu-item {
    height: 50px;
    line-height: 50px;
  }
  .el-submenu__title {
    height: 50px;
    line-height: 50px;
  }
}
.menu .is-horizontal {
  display: none;
}

::v-deep.menu .el-scrollbar__wrap {
  overflow-x: hidden !important;
}
</style>
