<template>
  <!-- 
    <div>hello</div> 
    多学一招：在elementui中组件名就是类名
  -->
  <!-- layout -->
  <div class="layout">
    <!-- menu -->
    <Menu :menuWidth="width" />
    <!-- menu -->

    <!-- main -->
    <div class="main">
      <!-- top -->
      <!-- <Top
        :menuWidth="width"
        @changeMenu="width = width == '200px' ? '64px' : '200px'"
      /> -->
      <Top :menuWidth="width" @changeMenu="changeMenu($event)" />
      <!-- top -->

      <!-- content -->
      <div class="content">
        <!-- <Test /> -->
        <transition enter-active-class="animated fadeInRight">
          <router-view></router-view>
        </transition>
      </div>
      <!-- content -->
    </div>
    <!-- main -->
  </div>
  <!-- layout -->
</template>

<script>
// import Test from "./components/Test.vue";
// import Menu from "./components/menu/Index.vue";
// import Top from "./components/top/Index.vue";

// import { Test, Menu, Top } from "./components";
import { Menu, Top } from './components'

export default {
  data() {
    return {
      width: '200px'
    }
  },
  components: {
    // Test,
    Menu,
    Top
  },
  methods: {
    changeMenu(val) {
      // console.log(val, 'val')
      this.width = val == '200px' ? '64px' : '200px'
    }
  }
}
</script>

<style lang="scss" scoped>
// layout
.layout {
  display: flex;
  height: 100%;

  // main
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    // content
    .content {
      flex: 1;
      width: 96%;
      margin: auto;
      overflow-x: hidden;
      overflow-y: auto;
      // background-color: green;
    }
  }
}
</style>
