<template>
  <el-dialog
    title="更新密码"
    :visible.sync="state"
    width="30%"
    :before-close="handleClose"
  >
    <!-- 内容 -->
    <tz-form
      width="80px"
      :formConfig="formConfig"
      :formBtns="formBtns"
      :row="passwd"
      @submit="submitFn"
    ></tz-form>
    <!-- 内容 -->
  </el-dialog>
</template>

<script>
import { SHA256 } from '@/utils/util'
export default {
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    state() {
      this.passwd = {}
    }
  },
  data() {
    return {
      // 表单配置
      passwd: {},
      formConfig: [
        {
          label: '新密码',
          field: 'password',
          disabled: true,
          type: 'password',
          showPwd: true,
          rules: [{ required: true, message: '密码必填', trigger: 'blur' }]
        },
        {
          label: '确认密码',
          disabled: true,
          field: 'repassword',
          type: 'password',
          showPwd: true,
          rules: [{ required: true, message: '新密码必填', trigger: 'blur' }]
          // },
          // {
          //   label: '选择角色',
          //   field: 'role_id',
          //   type: 'select',
          //   payload: [
          //     { label: '超级管理员', value: 1 },
          //     { label: '财务', value: 2 },
          //     { label: '人事', value: 3 }
          //   ],
          //   rules: [{ required: true, message: '选择角色必填', trigger: 'blur' }]
        }
      ],

      formBtns: [
        {
          width: '185px',
          content: '更新',
          type: 'primary'
        }
        // {
        //   content: "重置",
        //   type: "",
        // },
      ]
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    submitFn(formData) {
      if (formData.password !== formData.repassword) {
        this.$message({
          type: 'error',
          message: '两次输入密码不一致！'
        })
        return
      }
      this.$emit('confirm', SHA256(formData.password))
      // console.log('更新数据处理', formData)
    }
  }
}
</script>

<style></style>
