import request from '@/utils/request'
import qs from 'qs'

export const common = {
  get(url, params) {
    if (params) {
      const tempParams = {}
      Object.keys(params).forEach((key) => {
        if (params[key] !== '' && params[key] !== undefined)
          tempParams[key] = params[key]
      })
      params = tempParams
    }
    return request({
      method: 'get',
      url: url,
      params
    })
  },
  post(url, params, data) {
    if (params) {
      const tempParams = {}
      Object.keys(params).forEach((key) => {
        if (params[key] !== '' && params[key] !== undefined)
          tempParams[key] = params[key]
      })
      params = tempParams
    }
    if (data) {
      const tempData = {}
      Object.keys(data).forEach((key) => {
        if (data[key] !== '' && data[key] !== undefined)
          tempData[key] = data[key]
      })
      data = tempData
    }
    return request({
      method: 'POST',
      url: url,
      params,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data, { arrayFormat: 'brackets' })
    })
  },
  jsonPost(url, params, data) {
    if (params) {
      const tempParams = {}
      Object.keys(params).forEach((key) => {
        if (params[key] !== '' && params[key] !== undefined)
          tempParams[key] = params[key]
      })
      params = tempParams
    }
    if (data) {
      const tempData = {}
      Object.keys(data).forEach((key) => {
        if (data[key] !== '' && data[key] !== undefined)
          tempData[key] = data[key]
      })
      data = tempData
    }
    return request({
      method: 'POST',
      url: url,
      params,
      headers: { 'content-type': 'application/json;charset=UTF-8' },
      data: JSON.stringify(data)
    })
  }
}
