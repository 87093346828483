import request from './request'
import router from '@/router/index'
import {
  SHA256
} from '@/utils/util'
import qs from 'qs'
import store from '@/store'

export async function brequest({
  url,
  method,
  headers,
  data,
  dataType
}) {
  let rdata = {}
  rdata.url = url
  rdata.method = !method ? 'post' : method
  rdata.dataType = !dataType ? '' : dataType
  rdata.headers = !headers ? {
      'content-type': 'application/x-www-form-urlencoded'
    } :
    headers
  if (data) {
    const tempData = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== null && data[key] !== undefined)
        tempData[key] = data[key]
    })
    data = tempData
  }
  if (dataType && dataType === 'json') {
    // json格式
    rdata.data = JSON.stringify(data)
  } else {
    rdata.data = qs.stringify(data || '', {
      arrayFormat: 'brackets'
    })
  }

  let ret = await request(rdata)
  if (ret.msg == '未鉴权') {
    router.push('/login')
    return {}
  }
  if (ret.code >= 200 && ret.code < 300) {
    if (ret.code === 200) {
      return ret.data
    }
    return {}
  } else if ((ret.code > 300) & (ret.code < 400)) {
    let loginName = localStorage.getItem('loginName') || ''
    let password = localStorage.getItem('password') || ''
    let loginSuccess = await login({
      loginName: loginName,
      password: password
    })
    if (loginSuccess) {
      ret = await request(rdata)
      if (ret.code >= 200 && ret.code < 300) {
        if (ret.code === 200) {
          return ret.data
        }
        return {}
      }
    }
    let hosturl = location.host
    let path = location.href.split(hosturl, 2)[1]
    path = path.split('redirectUrl=')
    path = path[path.length - 1]
    if (path.includes('login') || path.length <= 1) {
      router.push('/login')
    } else {
      router.push('/login?redirectUrl=' + encodeURIComponent(path))
    }
  } else {
    return null
  }
}

export async function login(userInfo) {
  if (!userInfo || !userInfo.loginName || !userInfo.password) {
    return false
  }
  let salt = await request({
    url: '/user/genSalt',
    method: 'post'
  })
  if (salt.code !== 200) return false
  let user = {}
  user.loginName = userInfo.loginName
  user.timeStamp = salt.data.rkey
  user.password = SHA256(userInfo.password + salt.data.rvalue)
  console.log(qs.stringify(user), '-=-34343===-')
  let token = await request({
    url: '/user/login',
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(user)
  })
  if (token.code !== 200) return false
  store.commit('login/STORE_LOGINNAME', userInfo.loginName)
  store.commit('login/STORE_PASSWORD', userInfo.password)
  store.commit('login/STORE_TOKEN', token.data.token)
  store.commit('login/STORE_TOKENEXPIRETIME', token.data.expireTime)
  return true
}