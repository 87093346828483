import Vue from 'vue'
Vue.mixin({
  methods: {
    createTip(res, url, state = 201, callback) {
      if (res.meta.state == state) {
        this.$notify({
          title: '操作成功',
          message: '',
          type: 'success'
        })
        if (callback) {
          callback()
        } else {
          // this.jump("/users");
          this.jump(url)
        }
      } else {
        this.$notify({
          title: res.meta.msg,
          message: '',
          type: 'error'
        })
      }
    },
    jump(path) {
      // console.log(this.$route);
      if (path === this.$route.path) return
      return this.$router.push(path)
    },
    deleteFn(row, success, error) {
      console.log('公共的删除methods，当前行数据：', row)

      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          console.log('delete sure')

          if (success) success(row)
        })
        .catch(() => {
          console.log('delete cancel')

          if (error) error()
        })
    },
    getImgUrl(url) {
      let reg = /^(https?:)/
      if (!url) return ''
      if (reg.test(url)) {
        return url
      } else {
        return 'https://dashboard.jsjxtzjc.com/api/file' + url
        // return '' + url
      }
    }
  }
})
