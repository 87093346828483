<template>
  <el-table
    :data="tableData"
    stripe
    style="width: 100%"
    border
    :cell-style="needStyle"
    :show-summary="summary"
    :summary-method="getSummaries"
  >
    <template v-for="(item, index) in columns">
      <!-- 默认 -->
      <el-table-column
        :key="index"
        v-if="item.field"
        :label="item.title"
        :prop="item.field"
        :width="item.width"
        sortable
        :sort-method="
          (a, b) => {
            return a[item.field] - b[item.field]
          }
        "
      >
      </el-table-column>

      <!-- 默认 end -->
      <!-- 自定义 -->
      <el-table-column
        :label="item.title"
        :key="index + 'a'"
        :prop="item.payload.field"
        :width="item.width"
        v-else
      >
        <template slot-scope="scope">
          <!-- switch v-model="scope.row.state" -->
          <!-- 点击跳转 -->
          <span v-if="item.type == 'detail'">
            <a class="aClick" @click="item.payload.click(scope.row)">
              {{ scope.row[item.payload.field] }}
            </a>
          </span>
          <!-- 可编辑表格 -->
          <el-input
            v-if="item.type == 'isEdit'"
            v-model="scope.row[item.payload.field]"
            placeholder="请输入"
          ></el-input>
          <el-switch
            v-if="item.type === 'switch'"
            v-model="scope.row[item.payload.field]"
            @change="item.payload.change(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
          <!-- switch -->

          <!-- btn -->
          <template v-if="item.type === 'btn'">
            <el-button
              size="mini"
              :type="item.type"
              v-for="(item, index) in item.payload"
              :key="index"
              @click="item.click(scope.row)"
            >
              <span v-if="item.name">{{ item.name }}</span>
              <i v-else :class="item.icon"></i>
            </el-button>
          </template>
          <!-- btn -->
        </template>
      </el-table-column>
      <!-- 自定义 end -->
    </template>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      required: true,
      type: Array
    },
    // 是否合计
    summary: {
      required: false,
      type: Boolean
    },
    columns: {
      /*
      [
          {title, field, ...}
      ]
      */
      required: true,
      type: Array
    }
  },
  methods: {
    // 合计金额 el-table-column必须得加prop,否则  column.property为undefined
    getSummaries(param) {
      const { columns, data } = param
      console.log(param, '合计行')

      const sums = [] //声明变量
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (
          column.property &&
          column.property != 'personName' &&
          column.property != 'personId' &&
          column.property != 'cardNo' &&
          column.property != 'coefficient'
          //不需要显示和合计纵列字段名
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = sums[index].toLocaleString()
        } else {
          sums[index] = '--'
        }
      })
      return sums
    },
    // 样式
    needStyle(data) {
      // console.log(data)
      let cellStyle = ''
      switch (data.column.label) {
        case '合计1':
          cellStyle = 'color:blue'
          break
        case '合计2':
          cellStyle = 'color:blue'
          break
        // case 'test3':
        //   cellStyle = 'color:blue'
        //   break
        default:
          cellStyle = ''
      }
      switch (data.column.label) {
        case '合计':
          cellStyle = 'color:red;'
          break
        case '合计1':
          cellStyle = 'color:red'
          break
        case '合计2':
          cellStyle = 'color:red'
          break
        // case 'test3':
        //   cellStyle = 'color:blue'
        //   break
        default:
          cellStyle = ''
      }
      return cellStyle
    }
  },
  mounted() {
    console.log(this.summary, '-------------------')
    if (this.summary)
      this.$emit('getSummaries', this.getSummaries) /* 向外暴露set方法 */
  }
}
</script>

<style>
a.aClick {
  color: #06f;
  text-decoration: none;
}
a.aClick:link {
  color: #06f;
  text-decoration: none;
} /* 未访问的链接 */
a.aClick:visited {
  color: #999;
  /* text-decoration: line-through; */
} /* 已访问的链接 */
a.aClick:hover {
  color: #f00;
  text-decoration: underline;
} /* 鼠标移动到链接上 */
</style>
