import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 配置 ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

function RepairProps(cmp) {
  ;
  (cmp.mixins || []).forEach((mixin) => {
    if (mixin.props && mixin.props.placement) {
      const defaultValue = mixin.props.placement.default
      mixin.data = new Proxy(mixin.data, {
        apply(target, thisArg, argArray) {
          const res = Reflect.apply(target, thisArg, argArray)
          return {
            ...(res || {}),
            placement: defaultValue
          }
        }
      })
      delete mixin.props.placement
    }
    if (mixin.mixins && mixin.mixins.length > 0) {
      RepairProps(mixin)
    }
  })
}

RepairProps(ElementUI.DatePicker)
RepairProps(ElementUI.TimePicker)
RepairProps(ElementUI.TimeSelect)

Vue.use(ElementUI)

// 全局特效
import animated from 'animate.css'
Vue.use(animated)

// 全局组件
import tzForm from '@/components/tz-form/Index'
import tzTable from '@/components/tz-table/Index'
import tzCard from '@/components/tz-card/Index'
// 图片预览组件
import ImagePreview from '@/components/ImagePreview/index'
Vue.use(function () {
  Vue.component('tzForm', tzForm)
  Vue.component('tzTable', tzTable)
  Vue.component('tzCard', tzCard)
  Vue.component('ImagePreview', ImagePreview)

})

// 全局混入
import '@/utils/mixins'

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')